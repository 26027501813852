<script>
import { SuawInputGroup, SuawHeading, SuawPageHeaderActions, SuawSection, SuawParagraph, SuawDivider, SuawThumbnail } from "@/components";
import AuthorBio from "@/features/articles/components/AuthorBio.vue";
import AuthorPosts from "@/features/articles/components/AuthorPosts.vue";
import * as db from "../data.js";

export default {
  name: "ArticleFrame",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawSection,
    SuawParagraph,
    SuawDivider,
    SuawThumbnail,
    AuthorBio,
    AuthorPosts,
    SuawPageHeaderActions
  },
  props: {
    articleId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      articles: db.articles()
    };
  },
  computed: {
    article() {
      return this.articles.filter(a => a.id === this.articleId)[0];
    },
    authorText() {
      return `By <a href="#about">${this.article.author}</a> on ${this.article.posted}`;
    }
  }
};
</script>

<template>
  <SuawInputGroup v-if="article" field-one-size="0" field-two-size="0" :centered="true" direction="column" group-gap="triple">
    <SuawPageHeaderActions back-text="Back to Articles" :back-route-object="{ name: 'ArticleDiscovery' }" />
    <SuawDivider />
    <SuawSection section-size="medium">
      <SuawInputGroup group-style="default" direction="column">
        <SuawHeading level="2" :content="article.heading" />
        <SuawParagraph :text="authorText" />
        <SuawDivider type="inner" color="medium" top-distance="default" bottom-distance="closer" />
      </SuawInputGroup>
    </SuawSection>
    <SuawSection section-size="medium">
      <SuawInputGroup group-style="default" direction="column" group-gap="triple">
        <div>
          <SuawThumbnail size="fill" :content="article.thumbnail" />
        </div>
        <slot></slot>
        <SuawDivider type="inner" color="medium" top-distance="default" bottom-distance="default" />
        <AuthorBio id="bio" :author-id="article.authorId" />
      </SuawInputGroup>
    </SuawSection>
    <SuawDivider class="article-frame-bottom-divider" />
    <SuawPageHeaderActions back-text="Back to Articles" :back-route-object="{ name: 'ArticleDiscovery' }" />
    <AuthorPosts id="about" :author-id="article.authorId" />
  </SuawInputGroup>
</template>

<style lang="scss" scoped>
.article-frame-bottom-divider {
  margin-top: 32px;
}
</style>
